
import HeaderSection from '../components/Header/Header'
import HeroSection from '../components/HeroSection/HeroSection'
import Records from '../components/Records/Records'
import LiveClasses from '../components/LiveClasses/LiveClasses'
import ExpertTeam from '../ExpertTeam/ExpertTeam'
import Features from '../components/Features/Features'
import LatestCourses from '../components/LatestCourses/LatestCourses'
import Testimonials from '../components/Testimonials/Testimonials'
import Footer from '../components/Footer/Footer'
import DoubtResolving from '../components/DoubtResolving/DoubtResolving'
import React, { useState, useEffect } from 'react';
import PopupForm from '../components/Popupform/Popupform'
import WhatsAppChat from '../components/WhatsappChat/WhatsAppChat'

const HomePage = () => {

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup when the homepage loads
    setShowPopup(true);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };


  return (
    <div>
    <HeaderSection />
     
     <HeroSection />
     <Records/>
     <DoubtResolving/>
     <LiveClasses/>
     <ExpertTeam/>
     <Features/>
    <LatestCourses/>
     <Testimonials/>
     <Footer/>
     {showPopup && <PopupForm onClose={closePopup} />} 
     <WhatsAppChat/>
    </div>
  )
}

export default HomePage