import React, { useState } from 'react';
import './LiveClasses.css';
// Replace 'yourImagePath' with the actual path to your image in the Images folder.
import LiveClassImage from '../../Images/livescreen.jpg';
import PopupForm from '../Popupform/Popupform';

const LiveClasses = () => {
  const closePopup = () => {
    setShowPopup(false);
  };
  const [showPopup, setShowPopup] = useState(false);
  return (
    <section className="live-classes-section">
      <h2>Interactive Live Classes</h2>
      <div className="live-classes-container">
        {/* Image instead of Mobile Frame and Video */}
        <img className="live-class-image" src={LiveClassImage} alt="Live Class" />

        {/* Right-hand Description */}
        <div className="live-classes-description">
          <h3>What are Live Classes?</h3>
          <p>
            Live classes are interactive sessions where students can participate just like they would in a physical classroom. 
            Students can ask questions via live chat or by enabling their microphone. Teachers can share their screens, 
            explain topics, and address student queries in real-time.
          </p>
          <p>
            Experience the convenience of learning from home with the engagement of live instruction, 
            including personalized attention from the teacher, real-time explanations, and collaborative discussions.
          </p>

          {/* Experience Now Button */}
          <button className="experience-btn" onClick={()=>setShowPopup(true)}>Experience Now</button>
        </div>
      </div>
      {showPopup && <PopupForm onClose={closePopup} />} 
    </section>
    
  );
};

export default LiveClasses;
