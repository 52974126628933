import React, { useState } from 'react';
import './Contact.css';
import HeaderSection from '../Header/Header';
import Footer from '../Footer/Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { firstName, lastName, email, phone, message } = formData;
  
    // Prepare the request body
    const requestBody = {
      name: `${firstName} ${lastName}`,
      phone: phone,
      email: email,
      message: message,
    };

    console.log("my contact info is " + JSON.stringify(requestBody));
  
    try {
      // Send the form data to the provided URL
      const response = await fetch('https://epilot.in/student/create_inq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
    const result = await response.json();
      // Check for successful submission
      if (result.success) {
        console.log('Form submitted successfully');
        // Show the modal on successful submission
        setIsModalOpen(true);
      } 
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <>
      <HeaderSection />

      <div className="contact-container">
        <h1 className="contact-title">Contact Us</h1>
        <p className="contact-subtitle">Any question or remarks? Just write us a message</p>

        <div className="contact-content">
          {/* Left side: Contact info */}
          <div className="contact-info">
            <h3 className="contact-info-title">Contact Information</h3>
            <p className="contact-info-description">Fill up the form and our team will get back to you within 24 hours.</p>
            <div className="contact-detail">
              {/* Phone Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path d="M20 15.5c-1.22 0-2.39-.2-3.5-.57-.35-.11-.75-.03-1.02.24l-2.2 2.2c-3.07-1.42-5.61-3.95-7.03-7.03l2.2-2.2c.27-.27.35-.67.24-1.02-.37-1.1-.57-2.28-.57-3.5C8 3.67 7.33 3 6.5 3h-4C1.67 3 1 3.67 1 4.5 1 13.39 7.61 20 16.5 20c.83 0 1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5z" />
              </svg>
              <p>+91 7393939143</p>
            </div>
            <div className="contact-detail">
              {/* Email Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
              </svg>
              <p>Sisyaclass@gmail.com</p>
            </div>
            <div className="contact-detail">
              {/* Location Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
              </svg>
              <p>SISIYA EDTECH PVT LTD, Bhupathi Surya Central Mall, 2nd Floor, New Colony Rd, Manavaripeta, P & T Colony, Dondaparthy, Visakhapatnam, Andhra Pradesh, 530016</p>
            </div>

            {/* Google Maps iframe */}
            <div className="map-container">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3816.539897623963!2d83.28706727591467!3d17.6868156878759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431f9093c35d%3A0x3030cce6bc74b5b5!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1633171684480!5m2!1sen!2sin"
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>

          {/* Right side: Contact form */}
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">Send Message</button>
            </form>
          </div>
        </div>
      </div>

      {/* Modal for success message */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <h2>Success!</h2>
            <p>Your query has been submitted successfully. Our team will get back to you shortly.</p>
            <button className="close-btn" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Contact;
