import React from 'react';
import './Features.css'; // Import the CSS file

const Features = () => {
  return (
    <section className="video-section">
      <h2>Key Features</h2>
      <div className="video-container">
        {/* Left Column Cards */}
        <div className="left-column">
          <div className="card-left">
            <div className="card-content">
              {/* Interactive Live Classes - Video Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="icon"
                width="40px"
                height="40px"
              >
                <path d="M21 3H3c-1.1 0-1.99.9-1.99 2L1 17c0 1.1.9 2 2 2h7v2H6v2h12v-2h-4v-2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12zm-10-1h8v-6h-8v6z"/>
              </svg>
              <h3>Interactive Live Classes</h3>
              <p>Experience live, two-way interaction with experienced teachers, allowing students to ask questions and participate in real-time.</p>
            </div>
          </div>

          <div className="card-left">
            <div className="card-content">
              {/* Instant Doubt Resolution - Question Mark Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="icon"
                width="40px"
                height="40px"
              >
                 <path d="M12 3C7.03 3 3 6.95 3 12c0 2.21.9 4.2 2.35 5.65L3 21l3.35-2.35C7.8 20.1 9.79 21 12 21c4.97 0 9-3.95 9-9 0-5.05-4.03-9-9-9zm1 12h-2v-2h2v2zm0-4h-2V7h2v4z"/>
              </svg>
              <h3>Instant Doubt Resolution</h3>
              <p>Got a question? Get instant help within 10 minutes through live video calls with your teacher, ensuring that no doubt goes unresolved.</p>
            </div>
          </div>

          <div className="card-left">
            <div className="card-content">
              {/* IIT JEE Foundation Icon - Graduate Cap Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="icon"
                width="40px"
                height="40px"
              >
               <path d="M12 2C7.03 2 3 6.03 3 11c0 2.39 1.02 4.6 2.78 6.19-.2 1.04-.78 2.26-1.52 3.31-.38.52.03 1.25.66 1.25.34 0 .67-.17.86-.5.87-1.31 1.52-3.13 1.73-4.22.91.27 1.86.43 2.89.43s1.98-.16 2.89-.43c.21 1.09.86 2.91 1.73 4.22.19.33.52.5.86.5.63 0 1.04-.73.66-1.25-.74-1.05-1.32-2.27-1.52-3.31C19.98 15.6 21 13.39 21 11c0-4.97-4.03-9-9-9zm0 18c-1.02 0-2.02-.14-2.98-.42l-.5.26c-.56.29-1.04.57-1.5.82.23-.62.42-1.25.58-1.88l.18-.74-.58-.55C5.33 16.49 4 13.86 4 11 4 6.58 7.58 3 12 3s8 3.58 8 8c0 2.86-1.33 5.49-3.2 7.24l-.58.55.18.74c.16.63.35 1.26.58 1.88-.46-.25-.94-.53-1.5-.82l-.5-.26c-.96.28-1.96.42-2.98.42zM12 8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/>
              </svg>
              <h3>IIT JEE Foundation & Advanced Courses</h3>
              <p>Prepare for competitive exams like IIT JEE with foundational courses that give you a head start with expert teacher.</p>
            </div>
          </div>
        </div>

        {/* Center Video */}
        <div className="video-wrapper">
          <img src={require('../../Images/features.png')} alt="Curriculum" />
        </div>

        {/* Right Column Cards */}
        <div className="right-column">
          <div className="card-right">
            <div className="card-content">
              {/* Coding & Robotics Icon - Robot Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="icon"
                width="40px"
                height="40px"
              >
                 <path d="M8.29 6.71a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.42l-6 6a1 1 0 0 1-1.42-1.42L12.59 12 8.29 6.71z"/>
              </svg>
              <h3>Specialized Courses in Coding & Robotics</h3>
              <p>Equip your child with the skills of the future through our specialized coding and robotics classes, designed to spark innovation and creativity.</p>
            </div>
          </div>

          <div className="card-right">
            <div className="card-content">
              {/* Comprehensive Curriculum Icon - Book Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="icon"
                width="40px"
                height="40px"
              >
                 <path d="M21 3h-7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16h-7V5h7v14zM3 3v18c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2zm2 16h7V5H5v14z"/>

              </svg>
              <h3>Comprehensive Curriculum</h3>
              <p>SISYA CLASS covers a wide array of subjects for CBSE, ICSE, IB, and State Board exams, ensuring your child is fully prepared for success.</p>
            </div>
          </div>

          <div className="card-right">
            <div className="card-content">
              {/* Subscription Plans Icon - Wallet Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="icon"
                width="40px"
                height="40px"
              >
                <path d="M21 4H7c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H7V6h14v12zm-2-9h-5v3h5v-3zM5 6H3v12h2V6z"/>
              </svg>
              <h3>Subscription Plans</h3>
              <p>Access all subjects, live classes, instant doubt resolution, and course materials for a full academic year.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
