import React from 'react';
import './About.css';
import HeaderSection from '../Header/Header';
import Footer from '../Footer/Footer';
import logo from '../../Images/logo.png';
const About = () => {
  return (
    <div>
      <HeaderSection /> {/* Existing header component */}
      
      <section className="about-us-section">
        <div className="about-us-content">
          <div className="about-image-container">
            <img src={logo} alt="About us" className="about-image" />
          </div>

          <div className="about-us-text">
            <h2>Welcome to SISYA CLASS – Shaping the Future of Education</h2>
           
            <p>At SISYA CLASS, we believe that every child deserves access to world-class education, no matter where they live. Our platform provides interactive live classes for students from Class 1 to 12, focusing on subjects like Math, Physics, Chemistry, specifically design Coding, Robotics classes and IIT JEE Foundation. Designed to meet the educational needs of students studying under CBSE, ICSE, IB, and State Boards, we are committed to delivering quality learning experiences through our innovative Live Streaming and Instant Doubt Resolution features.</p>
          </div>
        </div>

       
      </section>
      <section className="our-vision-section">
        <div className="vision-content">
          <h2>OUR VISION</h2>
          <p>To empower students from Tier 1, 2, and Tier 3 cities by making world-class education accessible, interactive, and personalized.</p>
          <p>We envision a future where every student, regardless of geographical limitations, can access high-quality learning resources and expert teachers, helping them unlock their full academic potential.</p>
        </div>
      </section>
      <section className="who-we-are-section">
        <div className="who-we-are-content">
          <div className="who-we-are-text">
            <h2>Why SISYA CLASS?</h2>
            <ul>
              <li>Live Streaming Classes: Attend engaging and interactive classes from the comfort of your home.</li>
              <li>Instant Doubt Resolution: Get your doubts cleared within 10 minutes through live video calls with top teachers.</li>
              <li>Tailored Curriculum: We cater to CBSE, ICSE, IB, and State Boards, ensuring a personalized learning experience.</li>
              <li>Wide Subject Coverage: From core subjects to advanced Coding and Robotics, we offer a comprehensive range of topics.</li>
              <li>Affordable Learning Plans: Choose from yearly or weekly plans that fit your schedule and budget.</li>
              <li>For All Students: Whether you are preparing for board exams or competitive exams like IIT JEE, SISYA CLASS has courses designed just for you.</li>
            </ul>
          </div>

          <div className="who-we-are-image-container">
            <img src="https://via.placeholder.com/400" alt="Who we are" className="who-we-are-image" />
          </div>
        </div>
      </section>

      <Footer /> {/* Existing footer component */}
    </div>
  );
};

export default About;
