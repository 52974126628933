import React from 'react';
import './RefundPolicy.css';
import HeaderSection from '../Header/Header';
import Footer from '../Footer/Footer';

const RefundPolicy = () => {
  return (
    <>
      <HeaderSection />

      <div className="refund-container">
        <h1 className="refund-title">Refund Policy</h1>

        <div className="refund-content">
          <h2>1. Overview</h2>
          <p>
            Our refund policy has been designed to provide clarity on how we handle refunds. We aim to be transparent and fair, and ensure that users understand their rights regarding refunds.
          </p>

          <h2>2. Eligibility for Refunds</h2>
          <p>
            Refunds are only provided under certain circumstances. If a user experiences technical issues that prevent the use of the platform, they may be eligible for a refund. Refund requests must be submitted within 30 days of payment.
          </p>

          <h2>3. Non-Refundable Services</h2>
          <p>
            Payments made for subscription-based services are non-refundable unless specified otherwise. Once a user has accessed or used a subscription, they are not eligible for a refund.
          </p>

          <h2>4. Partial Refunds</h2>
          <p>
            In certain situations, partial refunds may be granted. This includes cases where a service was partially rendered, but issues occurred that affected the user’s experience. Partial refunds are granted at the sole discretion of the company.
          </p>

          <h2>5. How to Request a Refund</h2>
          <p>
            Users seeking a refund must contact our support team at support@sisyaclasses.com. All refund requests will be reviewed, and users will be notified of the outcome within 7 business days.
          </p>

          <h2>6. Processing Refunds</h2>
          <p>
            If a refund is approved, it will be processed within 10 business days. Refunds will be issued to the original payment method used during the transaction. Processing times may vary based on the payment method.
          </p>

          <h2>7. Changes to This Refund Policy</h2>
          <p>
            We reserve the right to modify this Refund Policy at any time. Any changes will be posted on this page, and continued use of the platform following any changes indicates your acceptance of the updated policy.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about this Refund Policy, please contact us at support@sisyaclasses.com.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RefundPolicy;
