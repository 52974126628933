import React from 'react';
import './ExpertTeam.css';

const teachers = [
  {
    name: 'Naga Manasa Elchuri',
    degree: 'B.Tech.',
    university:'Amity University, Noida',
    experience: '6+ years of experience',
    hoursTaught: '3500+ hours taught',
    image: require('../Images/teacher1.webp'),
  },
  {
    name: 'Jaya Mishra.',
    degree: 'M.B.A.',
    university:'DU University, Delhi',
    experience: '7+ years of experience',
    hoursTaught: '5500+ hours taught',
    image: require('../Images/teacher2.webp'),
  },
  {
    name: 'Bharti Aggarwal',
    degree: 'M.Sc. Mathematics',
    university:'Sharda University, Gr. Noida',
    experience: '8+ years of experience',
    hoursTaught: '4500+ hours taught',
    image: require('../Images/teacher3.webp'),
  },
];

const ExpertTeam = () => {
  return (
    <div className="teacher-profiles-section">
      <h2 className="section-title">Tutors Who Care</h2>
      <div className="profiles-container">
        {teachers.map((teacher, index) => (
          <div key={index} className="teacher-card">
            <img src={teacher.image} alt={teacher.name} className="teacher-image" />
            <div className="teacher-info">
              <h3 className="teacher-name">{teacher.name}</h3>
              <p className="teacher-degree">{teacher.degree}</p>
              <p className="teacher-experience">{teacher.experience}</p>
              <p className="teacher-hours">{teacher.university}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpertTeam;
