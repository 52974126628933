import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <>
      <section className="hero">
        <div className="hero-content">
          <img
            src={require('../../Images/bannerimage.jpg')}
            alt="Online Learning"
            className="hero-image"
          />

          {/* Row of Cards */}
          <div className="row-cards">
            <div className="card" style={{ backgroundColor: 'rgba(255, 183, 77, 0.9)' }}>
              <div className="icon-container">
                {/* Icon for Interactive Live Classes - Video Conference Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="icon"
                  width="40px"
                  height="40px"
                >
                   <path d="M21 3H3c-1.1 0-1.99.9-1.99 2L1 17c0 1.1.9 2 2 2h7v2H6v2h12v-2h-4v-2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12zm-10-1h8v-6h-8v6z"/>
                </svg>
              </div>
              <h3>Interactive Live Classes</h3>
              <p>All Subjects for Class 1-12</p>
            </div>

            <div className="card" style={{ backgroundColor: 'rgba(79, 195, 247, 0.9)' }}>
              <div className="icon-container">
                {/* Icon for Expert Teachers - Teacher Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="icon"
                  width="40px"
                  height="40px"
                >
  <path d="M12 2c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 10c2.67 0 8 1.34 8 4v4h-16v-4c0-2.66 5.33-4 8-4zm6 5v-1.5c0-.5-2-1.5-6-1.5s-6 1-6 1.5V17h12z"/>
                </svg>
              </div>
              <h3>Expert Teachers</h3>
              <p>Involving, Inventive, Inductive</p>
            </div>

            <div className="card" style={{ backgroundColor: 'rgba(129, 199, 132, 0.9)' }}>
              <div className="icon-container">
                {/* Icon for Doubt Resolution - Question Mark Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="icon"
                  width="40px"
                  height="40px"
                >
                  <path d="M12 3C7.03 3 3 6.95 3 12c0 2.21.9 4.2 2.35 5.65L3 21l3.35-2.35C7.8 20.1 9.79 21 12 21c4.97 0 9-3.95 9-9 0-5.05-4.03-9-9-9zm1 12h-2v-2h2v2zm0-4h-2V7h2v4z"/>
                  </svg>
               
              </div>
              <h3>Doubt Resolution</h3>
              <p>Gradual Depth to Build Solid Foundation</p>
            </div>
          </div>

          {/* Main Text Content */}
          <div className="text-content">
            <h3>
              Certified Top Teachers, Real-Time Lessons, Real Success! K12 Online LIVE Learning Platform
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
