import React, { useState } from 'react';
import './Popupform.css';

const PopupForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: '',
    selectedClass: '',
  });

  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClassSelect = (classValue) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedClass: classValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsSubmitted(true);
    }, 2000);
  };

  return (
    <div className="popup-form-overlay">
      <div className="popup-form">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        {isSubmitted ? (
          <div className="success-message">
            <h2>Your trial class has been booked successfully!</h2>
          </div>
        ) : (
          <div>
            <h2>Book Trial Class</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  required
                />
              </div>
              <div>
                <input
                  type="number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  placeholder="Enter Contact Number"
                  required
                />
              </div>
              <div>
              
                <div className="class-grid">
                  {['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6', 'Class 7', 'Class 8', 'Class 9', 'Class 10', 'Class 11', 'Class 12'].map((classValue) => (
                    <button
                      key={classValue}
                      type="button"
                      className={formData.selectedClass === classValue ? 'selected' : ''}
                      onClick={() => handleClassSelect(classValue)}
                    >
                      {classValue}
                    </button>
                  ))}
                </div>
              </div>
              <button type="submit" disabled={loading} className='formButton'>
                {loading ? 'Loading...' : 'Book Now'}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupForm;
