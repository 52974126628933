import React from 'react';
import './Records.css';
import askMeImg from '../../Images/askme1.png'; // Example image import
import raiseDoubtImg from '../../Images/raiseDoubt.png';
import resolveDoubtImage from '../../Images/secure.png';
import happyMomentsImg from '../../Images/customer-review.png';

const Records = () => {
  return (
    <section className="records-section">
      <div className="records-container">
        <div className="record-item">
          <img src={askMeImg} alt="Ask Me Icon" className="record-icon" />
          <p>ASK ME</p>
        </div>
        <div className="record-item">
          <img src={raiseDoubtImg} alt="Raise Doubt Icon" className="record-icon" />
          <p>Raise Doubt</p>
        </div>
        <div className="record-item">
          <img src={resolveDoubtImage} alt="Live Sessions Icon" className="record-icon" />
          <p>Resolve Doubt</p>
        </div>
        <div className="record-item">
          <img src={happyMomentsImg} alt="Happy Moments Icon" className="record-icon" />
          <p>Happy Moments</p>
        </div>
      </div>
    </section>
  );
};

export default Records;
