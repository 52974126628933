import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer'; // Assuming your Footer component is in the same directory
import './Courses.css'; // Custom CSS for this page
import HeaderSection from '../Header/Header';
import { useNavigate } from 'react-router-dom';
const Courses = () => {
  const [courses, setCourses] = useState([]); // State for courses
  const [selectedClass, setSelectedClass] = useState('Class1'); // State for selected class
  const [subjectNames, setSubjectNames] = useState({}); // Store subjects by course ID
  const [mentorDetails, setMentorDetails] = useState({}); // Store mentor details by course ID
  const navigate = useNavigate();

  // Sample images for now, you can replace with actual image paths from API
  const sampleImages = [
    'sampleImage1.jpg', 
    'sampleImage2.jpg', 
    'sampleImage3.jpg', 
    'sampleImage4.jpg'
  ];

  // Fetch courses from API
  const fetchCourses = async () => {
    const courseResponse = await fetch('https://epilot.in/rkadmin//get_all_courses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const courseResult = await courseResponse.json();

    if (courseResult.success) {
      const latestCourses = courseResult.bigCourses.map((course, index) => ({
        description: course.description,
        id: course.id, // Assuming there's an ID field in the course
        title: course.name,
        price: `₹${course.currentPrice}`, // Use currentPrice as per your structure
        lessons: `${course.session.length} Lessons`, // Assuming number of sessions as lessons
        lesson:course.session,
        students: `${course.enrolledStudents} Students`,
        image: sampleImages[index % sampleImages.length], // Use sample images for now
        grade: `Class${course.grade}`, // Assuming 'grade' field is available
        subjectList: course.subjectList, // Add subject list (subject IDs)
        mentorList: course.mentorList, // Add mentorList (mentor IDs)
        badge: course.isFree ? 'Free' : null, // Assuming you have a flag for free courses
        startDate: course.startDate,
        endDate: course.endDate
      }));

      setCourses(latestCourses); // Set courses in state

      // Fetch subjects and mentors for each course
      latestCourses.forEach(course => {
        fetchSubjectsForCourse(course.id, course.subjectList);
        fetchMentorsForCourse(course.id, course.mentorList);
      });
    }
  };

  // Fetch subject names for each course by ID
  const fetchSubjectsForCourse = async (courseId, subjectList) => {
    try {
      const subjectNamesArray = await Promise.all(subjectList.map(async (subjectId) => {
        const courseResponse = await fetch('https://epilot.in/student/get_subject_by_id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: subjectId })
        });
  
        const result = await courseResponse.json();
        if (result.success) {
          return result.subjects[0].name; // Assuming 'subjectName' is the field in response
        } else {
          return 'Unknown'; // Handle unknown subjects
        }
      }));

      // Update state with fetched subject names for the course
      setSubjectNames(prevState => ({
        ...prevState,
        [courseId]: subjectNamesArray
      }));
    } catch (error) {
      console.log('Unable to fetch subjects: ' + error);
    }
  };

  // Fetch mentor details for each course by mentor IDs
  const fetchMentorsForCourse = async (courseId, mentorList) => {
    try {
      const mentorDetailsArray = await Promise.all(mentorList.map(async (mentorId) => {
        const mentorResponse = await fetch('https://epilot.in/rkadmin/get_mentor_by_id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mentorId: mentorId }) // Pass mentorId as an int
        });

        const result = await mentorResponse.json();
        if (result.success) {
          return {
            name: result.mentor.name, // Assuming 'name' field exists in response
            image: result.mentor.image || 'https://via.placeholder.com/150', // Use placeholder image if no image found
          };
        } else {
          return {
            name: 'Unknown Mentor',
            image: 'https://via.placeholder.com/150' // Fallback image if mentor details can't be fetched
          };
        }
      }));

      // Update state with fetched mentor details for the course
      setMentorDetails(prevState => ({
        ...prevState,
        [courseId]: mentorDetailsArray
      }));
    } catch (error) {
      console.log('Unable to fetch mentors: ' + error);
    }
  };

  const handleEnrollClick = (course) => {
    navigate('/courseDetail', { state: { course } });
  };

  // Fetch courses on component mount
  useEffect(() => {
    fetchCourses();
  }, []);

  // Filter courses based on the selected class/grade
  const filteredCourses = courses.filter(course => course.grade === selectedClass);

  return (
    <>
      <HeaderSection /> {/* Use your existing header component */}
      
      <section className="course-page">
        <div className="class-filter">
          {['Class1', 'Class2', 'Class3', 'Class4', 'Class5', 'Class6', 'Class7', 'Class8', 'Class9', 'Class10', 'Class11'].map((cls, index) => (
            <button
              key={index}
              className={cls === selectedClass ? 'active' : ''}
              onClick={() => setSelectedClass(cls)} // Update selected class on button click
            >
              {cls}
            </button>
          ))}
        </div>

        <div className="course-grid">
          {filteredCourses.map((course, index) => (
            <div key={index} className="course-card">
              {course.badge && <span className="badge">{course.badge}</span>}
              <h3>{course.title}</h3>
              <p className="course-date">{course.lessons}</p>
              <div className="subjects">
                {/* Dynamically render subjects fetched from API */}
                {subjectNames[course.id] && subjectNames[course.id].map((subjectName, idx) => (
                  <span key={idx} className="subject-tag">{subjectName}</span>
                ))}
              </div>
              <div className="teachers">
  {/* Dynamically render mentor images with overlap */}
  {mentorDetails[course.id] && mentorDetails[course.id].map((mentor, idx) => (
    <img
      key={idx}
      src={mentor.image}
      alt="teacher"
      className="teacher-img-overlap"
      onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }} // Fallback image if fetch fails
    />
  ))}
</div>

              <div className="pricing">
                <span className="current-price">{course.price}</span>
              </div>
              <button className="enroll-btn" onClick={() => handleEnrollClick(course)}>Enroll</button>
            </div>
          ))}
        </div>
      </section>

      <Footer /> {/* Use your existing footer component */}
    </>
  );
};

export default Courses;
