import React from 'react';
import './Disclaimer.css';
import HeaderSection from '../Header/Header';
import Footer from '../Footer/Footer';

const Disclaimer = () => {
  return (
    <>
      <HeaderSection />

      <div className="disclaimer-container">
        <h1 className="disclaimer-title">Disclaimer</h1>

        <div className="disclaimer-content">
          <h2>1. General Information</h2>
          <p>
            The information provided on this platform is for general informational purposes only. While we strive to keep the content up-to-date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information.
          </p>

          <h2>2. No Professional Advice</h2>
          <p>
            The content available on this platform is not intended to be a substitute for professional advice. Always seek the advice of a qualified professional with any questions you may have regarding a particular topic.
          </p>

          <h2>3. Use at Your Own Risk</h2>
          <p>
            Any reliance you place on the information provided by this platform is strictly at your own risk. We are not liable for any loss or damage arising from your use of or reliance on the platform.
          </p>

          <h2>4. External Links</h2>
          <p>
            Our platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over the content, privacy policies, or practices of any third-party websites or services and do not assume any responsibility for them.
          </p>

          <h2>5. Limitation of Liability</h2>
          <p>
            Under no circumstances shall the platform or its affiliates be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of the platform.
          </p>

          <h2>6. Changes to This Disclaimer</h2>
          <p>
            We reserve the right to update or modify this Disclaimer at any time. Any changes will be posted on this page, and continued use of the platform following any changes constitutes your acceptance of the updated Disclaimer.
          </p>

          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about this Disclaimer, please contact us at support@sisyaclasses.com.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Disclaimer;
