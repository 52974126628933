import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

const HeaderSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        {/* Wrap the logo inside a Link that navigates to the home page */}
        <Link to="/">
          <img src={require('../../Images/logo.png')} alt="Logo"  />
        </Link>
      </div>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link to="../courses">Course</Link>
        <a href="#">Download</a>
        <a href="#">Carriers</a>
       
        <Link to="../faq">FAQs</Link>
        <Link to="../about">About</Link>
      </nav>
      <div className="hamburger-menu" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </header>
  );
};

export default HeaderSection;
