import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import FAQPage from './components/FAQs/FAQs';
import TermsCondition from './components/Terms/TermsCondition';
import PrivacyPolicy from './components/PrivacyAndPolicy/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy/RefundPolicy';
import Disclaimer from './components/Disclaimer/Disclaimer';
import Contact from './components/Contact/Contact';
import Courses from './components/Courses/Courses';
import CourseDetail from './components/CourseDetails/CourseDetails';
import About from './components/About/About';

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect to home by default */}
        <Route path="/" element={<Navigate to="/home" />} />
        {/* Define routes */}
        <Route path="/home" element={<HomePage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/terms" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courseDetail" element={<CourseDetail />} />
        <Route path="/about" element={<About />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
