import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bottom-footer">
      <div className="footer-links">
        <Link to="../terms">Terms & Conditions</Link>
        <Link to="../privacy-policy">Privacy Policy</Link>
        <Link to="../refund-policy">Refund</Link>
        <Link to="../disclaimer">Disclaimer</Link>
        <Link to="../contact">Contact Us</Link>
      </div>

      <div className="footer-copyright">
        <p>Copyright © 2024 SISIYA EDTECH PVT. LTD. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
