import React from 'react';
import './TermsCondition.css';
import HeaderSection from '../Header/Header';
import Footer from '../Footer/Footer';

const TermsCondition = () => {
  
  return (
    <>
    <HeaderSection />
    
    <div className="terms-container">
      <h1 className="terms-title">Terms & Conditions</h1>

      <div className="terms-content">
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our platform, you agree to be bound by these Terms and Conditions. If you do not agree with any of the terms outlined below, please do not use our services.
        </p>

        <h2>2. Use of Platform</h2>
        <p>
          The platform is intended to provide students with educational resources and tools, including real-time doubt solving with teachers. Any misuse of the platform, including reverse engineering or unauthorized access, is strictly prohibited.
        </p>

        <h2>3. User Responsibilities</h2>
        <p>
          As a user of the platform, you are responsible for maintaining the confidentiality of your account and password. You are fully responsible for all activities that occur under your account.
        </p>

        <h2>4. Subscription and Payment</h2>
        <p>
          Users can access certain features of the platform by subscribing to paid plans. Payments can be made through various methods, including credit/debit cards, net banking, and payment gateways. All fees are non-refundable unless explicitly stated.
        </p>

        <h2>5. Real-time Doubt Resolving</h2>
        <p>
          Our core feature enables students to instantly connect with teachers to resolve doubts in real-time. While we strive to ensure availability, the platform may occasionally be unavailable due to maintenance or unforeseen circumstances.
        </p>

        <h2>6. Intellectual Property</h2>
        <p>
          All content available on the platform, including text, images, logos, and software, is the intellectual property of the company. Unauthorized use of any content is strictly prohibited.
        </p>

        <h2>7. Modification of Terms</h2>
        <p>
          We reserve the right to update or modify these Terms at any time. Continued use of the platform following any changes constitutes your acceptance of the updated terms.
        </p>

        <h2>8. Limitation of Liability</h2>
        <p>
          The platform provides educational resources as is, and the company is not liable for any damages that may result from using the platform. We do not guarantee uninterrupted access or error-free content.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of India. Any disputes arising from the use of the platform shall be subject to the jurisdiction of the courts of India.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at support@sisyaclasses.com.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default TermsCondition;
