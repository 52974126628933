import React from 'react';
import './DoubtResolving.css';
import doubtImage from '../../Images/doubtResolving.jpg'; // Update the image path

const DoubtResolving = () => {
  return (
    <div className="doubt-resolving-container">
      <div className="doubt-image">
        <img src={doubtImage} alt="Doubt Resolving" />
      </div>
      <div className="doubt-text">
        <h2>Ask Me Your Doubts Instantly</h2>
        <div className="stepper-container">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-content">
              <h3>Raise Your Doubt</h3>
              <p>Submit your question or concern.</p>
            </div>
          </div>

          <div className="step">
            <div className="step-number">2</div>
            <div className="step-content">
              <h3>Choose Your Teacher</h3>
              <p>Select an expert mentor to resolve your doubt.</p>
            </div>
          </div>

          <div className="step">
            <div className="step-number">3</div>
            <div className="step-content">
              <h3>Connect with Teacher</h3>
              <p>Start a live chat or video session with the mentor.</p>
            </div>
          </div>

          <div className="step">
            <div className="step-number">4</div>
            <div className="step-content">
              <h3>Doubt Resolved</h3>
              <p>Your doubt is resolved instantly with expert guidance.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubtResolving;
