import React, { useState } from 'react';
import './FAQs.css';
import HeaderSection from '../Header/Header'; // Importing Header
import Footer from '../Footer/Footer'; // Importing Footer

const FAQPage = () => {
  const [activeSections, setActiveSections] = useState([]);

  const faqData = [
    {
      title: 'General Questions',
      content: [
        {
          question: 'What is the primary feature of our platform?',
          answer: 'Our platform focuses on real-time doubt resolving. Students can instantly connect with a teacher, submit their doubt, and get it resolved through interactive sessions.',
        },
        {
          question: 'How does the doubt-resolving feature work?',
          answer: 'Students can create a doubt by providing details and attaching any necessary files. Once submitted, the student gets connected to a teacher in real-time to discuss and resolve the doubt instantly.',
        },
        {
          question: 'What subjects are covered under the doubt-resolving feature?',
          answer: 'We cover a wide range of subjects including Math, Physics, Chemistry, Biology, and more. Our experienced teachers are available for both school-level and competitive exam preparation doubts.',
        },
        {
          question: 'Is there a limit to the number of doubts a student can ask?',
          answer: 'No, there is no limit. Students can ask as many doubts as they need, and each doubt will be addressed by our expert teachers in real-time.',
        },
      ],
    },
    {
      title: 'Account and Subscription',
      content: [
        {
          question: 'How do I create an account on the platform?',
          answer: 'You can create an account by clicking on the "Sign Up" button on our homepage. You will need to provide basic information such as your email address, phone number, and a password.',
        },
        {
          question: 'How do I subscribe to a course?',
          answer: 'Once you are logged in, you can browse through our course offerings and select the one you are interested in. You will find a "Subscribe" button on the course page, which will guide you through the payment process.',
        },
        {
          question: 'What payment methods are accepted?',
          answer: 'We accept all major credit cards, debit cards, and payment gateways like PayPal. Additionally, we also support local payment methods specific to your region.',
        },
        {
          question: 'Can I cancel my subscription?',
          answer: 'Yes, you can cancel your subscription anytime by going to the "My Account" section and following the steps under the "Cancel Subscription" option. Please note that refunds are subject to our refund policy.',
        },
      ],
    },
    {
      title: 'Technical Support',
      content: [
        {
          question: 'I am facing technical issues during live doubt sessions. What should I do?',
          answer: 'If you encounter any technical issues during a live doubt session, try refreshing the page or checking your internet connection. If the issue persists, contact our support team via live chat or email support@edtechplatform.com.',
        },
        {
          question: 'How do I report a bug or technical problem?',
          answer: 'You can report any bugs or technical issues by going to the "Support" section and filling out the bug report form. Our team will address the issue as soon as possible.',
        },
      ],
    },
  ];

  const toggleSection = (index) => {
    if (activeSections.includes(index)) {
      setActiveSections(activeSections.filter((i) => i !== index));
    } else {
      setActiveSections([...activeSections, index]);
    }
  };

  return (
    <>
      {/* Include the Header at the top */}
      <HeaderSection />

      {/* FAQ Section */}
      <div className="faq-container">
        <h1 className="faq-title">Frequently Asked Questions</h1>
        {faqData.map((section, sectionIndex) => (
          <div key={sectionIndex} className="faq-section">
            <h2 className="faq-section-title">{section.title}</h2>
            {section.content.map((contentItem, contentIndex) => (
              <div key={contentIndex} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleSection(`${sectionIndex}-${contentIndex}`)}
                >
                  {contentItem.question}
                  <span className="faq-toggle-icon">
                    {activeSections.includes(`${sectionIndex}-${contentIndex}`) ? '-' : '+'}
                  </span>
                </div>
                {activeSections.includes(`${sectionIndex}-${contentIndex}`) && (
                  <div className="faq-answer">{contentItem.answer}</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Include the Footer at the bottom */}
      <Footer />
    </>
  );
};

export default FAQPage;
