import React, { useEffect, useState } from 'react';
import './Testimonials.css';

const testimonials = [
  {
    name: 'Ravi Sharma',
    image: require('../../Images/feedback1.jpg'),
    review: 'This platform has transformed the way I study. The live classes and personal interaction with teachers have made a huge difference.',
    backgroundColor: '#E6F7F5',
    rating:4,
  },
  {
    name: 'Anita Verma',
    image: require('../../Images/feedback2.jpg'),
    review: 'A wonderful learning experience! I can ask my doubts directly in class, and the teachers explain everything so clearly.',
    backgroundColor: '#F6EAFD',
    rating:3,
  },
  {
    name: 'Rahul Gupta',
    image: require('../../Images/feedback3.jpg'),
    review: 'I love the flexibility of attending classes from home. The real-time feedback from teachers keeps me motivated.',
    backgroundColor: '#EDF4FF',
    rating:5,
  },
  {
    name: 'Pooja Iyer',
    image: require('../../Images/feedback4.jpg'),
    review: 'The teachers are so interactive! I enjoy participating in live discussions and getting my doubts cleared immediately.',
    backgroundColor: '#FDF5E6',
  },
  {
    name: 'Amit Patel',
    image: require('../../Images/feedback5.jpg'),
    review: 'I’ve gained so much confidence in my subjects thanks to this platform. The detailed explanations make everything easier to grasp.',
    backgroundColor: '#FFFAF0',
    rating:4,
  },
  {
    name: 'Kavita Singh',
    image: require('../../Images/feedback6.jpg'),
    review: 'Learning has never been this fun and interactive! The teachers are always there to help, and the live sessions are excellent.',
    backgroundColor: '#F0FFF4',
    rating:5,
  },
  {
    name: 'Manish Tiwari',
    image: require('../../Images/feedback1.jpg'),
    review: 'The ability to attend classes at my convenience has made a huge impact. The teachers are amazing and always supportive.',
    backgroundColor: '#FFF5F7',
    rating:3,
  },
];

const Testimonials = () => {
  const [visibleTestimonials, setVisibleTestimonials] = useState(testimonials.slice(0, 4));
  const [flip, setFlip] = useState(false);

  useEffect(() => {
    const flipInterval = setInterval(() => {
      setFlip(true);
      setTimeout(() => {
        shuffleTestimonials();
        setFlip(false);
      }, 500); // Wait for flip animation to finish
    }, 3000); // Flip every 3 seconds

    return () => clearInterval(flipInterval);
  }, []);

  const shuffleTestimonials = () => {
    setVisibleTestimonials((prevTestimonials) => {
      const newVisible = [...prevTestimonials];
      const nextIndex = (testimonials.indexOf(newVisible[0]) + 1) % testimonials.length;
      newVisible.shift(); // Remove the first card
      newVisible.push(testimonials[nextIndex]); // Add the next testimonial
      return newVisible;
    });
  };
  const renderStars = (rating) => {
    return '★'.repeat(rating) + '☆'.repeat(5 - rating); // Create star rating string
  };
  return (
    <section className="testimonials-section">
      <h2>What Our Students Say</h2>
      <div className="testimonial-cards">
        {visibleTestimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`testimonial-card ${flip ? 'flip' : ''}`}
            style={{ backgroundColor: testimonial.backgroundColor }}
          >
            <div className="testimonial-content">
              <img src={testimonial.image} alt={testimonial.name} className="reviewer-image" />
              <div className="star-rating">{renderStars(testimonial.rating)}</div>
              <h3>{testimonial.name}</h3>
              <p>{testimonial.review}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
