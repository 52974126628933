import React from 'react';
import './PrivacyPolicy.css';
import HeaderSection from '../Header/Header';
import Footer from '../Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderSection />

      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>

        <div className="privacy-content">
          <h2>1. Introduction</h2>
          <p>
            We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our platform.
          </p>

          <h2>2. Information We Collect</h2>
          <p>
            We may collect personal information, such as your name, email address, and payment details, when you sign up or use our services. We also collect non-personal information, such as usage data, to improve our platform.
          </p>

          <h2>3. How We Use Your Information</h2>
          <p>
            Your personal information is used to provide and improve our services, process payments, and communicate with you. We may also use your information for security and legal purposes.
          </p>

          <h2>4. Sharing Your Information</h2>
          <p>
            We do not sell or rent your personal information to third parties. However, we may share your information with trusted partners who assist us in operating our platform or providing services to you.
          </p>

          <h2>5. Security</h2>
          <p>
            We take security seriously and implement industry-standard measures to protect your data from unauthorized access, alteration, or destruction.
          </p>

          <h2>6. Cookies</h2>
          <p>
            Our platform uses cookies to enhance your experience. Cookies help us understand how you use our platform and improve its performance. You can control cookie preferences through your browser settings.
          </p>

          <h2>7. Data Retention</h2>
          <p>
            We retain your personal information only for as long as necessary to provide you with our services and fulfill legal obligations. Afterward, your data is securely deleted.
          </p>

          <h2>8. Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal information at any time. You may also object to the processing of your data or request restrictions.
          </p>

          <h2>9. Changes to This Privacy Policy</h2>
          <p>
            We reserve the right to update or modify this Privacy Policy at any time. Continued use of the platform following any changes constitutes your acceptance of the updated policy.
          </p>

          <h2>10. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at privacy@sisyaclasses.com.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
