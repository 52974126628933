import React, { useEffect, useState } from 'react';
import './LatestCourses.css';
import { useNavigate } from 'react-router-dom';

// Sample images for display
const sampleImages = [
  require('../../Images/course1.jpg'), 
  require('../../Images/course2.jpg'), 
  require('../../Images/course3.jpg'), 
  require('../../Images/course4.jpg')
];

const LatestCourses = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch course data from the API
    const fetchCourses = async () => {
      const courseResponse = await fetch('https://epilot.in/rkadmin//get_all_courses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const courseResult = await courseResponse.json();
  
      if (courseResult.success) {
        const latestCourses = courseResult.bigCourses.slice(0, 4).map((course, index) => ({
          title: course.name,
          price: `₹${course.currentPrice}`, // Use currentPrice as per your structure
          lessons: `${course.session.length} Lessons`, // Assuming number of sessions as lessons
          students: `${course.enrolledStudents} Students`,
          image: sampleImages[index] // Use sample images for now
        }));
        setCourses(latestCourses);
        console.log(JSON.stringify(courseResult));
      }
    };

    fetchCourses();
  }, []);

  return (
    <section className="latest-courses-section">
      <h2 className="section-title">Our Courses</h2>
      <div className="courses-container">
        {courses.map((course, index) => (
          <div key={index} className="course-card">
            <img src={course.image} alt={course.title} className="course-image" />
            <div className="course-details">
             
              <h3>{course.title}</h3>
              <div className="course-meta">
                <span>{course.lessons}</span>
                <span>👥 {course.students}</span>
              </div>
              {/* Enroll Now Button */}
              <button className="enroll-now-btn" onClick={()=>navigate('../courses')}>Enroll Now</button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LatestCourses;
