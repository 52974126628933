import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // For WhatsApp Icon
import './WhatsAppChat.css'; // CSS for the button styling

const WhatsAppChat = () => {
  const phoneNumber = '+917393939143'; // Replace with your phone number
  const message = "Hello, I'm interested in your courses"; // Replace with your desired message

  const waLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className="whatsapp-chat">
      <a href={waLink} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
        <div className="whatsapp-button">
          <FaWhatsapp size={24} />
          <span className="whatsapp-text">Chat with us</span>
        </div>
      </a>
    </div>
  );
};

export default WhatsAppChat;
