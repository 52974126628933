import React, { useEffect, useState } from 'react';
import HeaderSection from '../Header/Header'; // Adjust the import based on your project structure
import './CourseDetails.css'; // Custom CSS for Course Detail Page
import { useLocation } from 'react-router-dom';

// Helper function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Get the suffix for the day (1st, 2nd, 3rd, etc.)
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${day}${daySuffix(day)} ${month} ${year}`;
};

const CourseDetail = () => {
  const location = useLocation();
  const { course } = location.state; // Course passed from previous page
  const [mentorDetails, setMentorDetails] = useState({}); // Store fetched mentor details
  const [subjectNames, setSubjectNames] = useState([]); // Store fetched subject names

  // Fetch mentor details for each course by mentor IDs
  const fetchMentorsForCourse = async (courseId, mentorList) => {
    try {
      const mentorDetailsArray = await Promise.all(mentorList.map(async (mentorId) => {
        const mentorResponse = await fetch('https://epilot.in/rkadmin/get_mentor_by_id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mentorId: mentorId }) // Pass mentorId as an int
        });

        const result = await mentorResponse.json();
        if (result.success) {
          return {
            name: result.mentor.name, // Assuming 'name' field exists in response
            image: result.mentor.image || 'https://via.placeholder.com/150', // Use placeholder image if no image found
          };
        } else {
          return {
            name: 'Unknown Mentor',
            image: 'https://via.placeholder.com/150' // Fallback image if mentor details can't be fetched
          };
        }
      }));

      // Update state with fetched mentor details for the course
      setMentorDetails(prevState => ({
        ...prevState,
        [courseId]: mentorDetailsArray
      }));
    } catch (error) {
      console.log('Unable to fetch mentors: ' + error);
    }
  };

  const fetchSubjectsForCourse = async (courseId, subjectList) => {
    try {
      const subjectNamesArray = await Promise.all(subjectList.map(async (subjectId) => {
        const courseResponse = await fetch('https://epilot.in/student/get_subject_by_id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: subjectId })
        });
  
        const result = await courseResponse.json();
        if (result.success) {
          return result.subjects[0].name; // Assuming 'subjectName' is the field in response
        } else {
          return 'Unknown'; // Handle unknown subjects
        }
      }));

      // Update state with fetched subject names for the course
      setSubjectNames(subjectNamesArray);
    } catch (error) {
      console.log('Unable to fetch subjects: ' + error);
    }
  };

  // Fetch mentors and subjects when the component mounts
  useEffect(() => {
    if (course.mentorList && course.mentorList.length > 0) {
      fetchMentorsForCourse(course.id, course.mentorList);
    }
    if (course.subjectList && course.subjectList.length > 0) {
      fetchSubjectsForCourse(course.id, course.subjectList);
    }
  }, [course]);

  return (
    <>
      <HeaderSection /> {/* Header section */}

      <div className="course-detail-container">
        {/* Left Side - Course Info */}
        <div className="course-info">
          {/* Updated Overview Section */}
          <div className="course-overview">
            <div className="overview-left">
              <h1>{course.title}</h1>
              <div className="subjects">
                {/* Render subject names fetched from the API */}
                {subjectNames.length > 0 && subjectNames.map((subject, index) => (
                  <span key={index} className="subject-tag">{subject}</span>
                ))}
              </div>
              <div className="course-date">
                <span>{formatDate(course.startDate)} - {formatDate(course.endDate)}</span>
              </div>
              <div className="teachers">
                {mentorDetails[course.id] && mentorDetails[course.id].map((mentor, index) => (
                  <div key={index} className="teacher-info">
                    <img
                      src={mentor.image}
                      alt={mentor.name}
                      className="teacher-img"
                      onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }} // Fallback image if fetch fails
                    />
                    <div>{mentor.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="overview-right">
              <div className="price">{course.isFree ? 'Free' : `${course.price}`}</div>
              <button className="enroll-btn">Enroll</button>
            </div>
          </div>

          {/* Course Lessons Section */}
          <div className="course-lessons">
            <h2>Lessons</h2>
            <ul className="lesson-list">
              {course.lesson.map((lesson, index) => (
                <li key={index} className="lesson-tile">
                  <div className="lesson-icon">
                    <span>{index + 1}</span>
                  </div>
                  <div className="lesson-details">
                    <div className="lesson-title">{lesson.detail}</div>
                    <div className="lesson-time">9:00 PM - 10:00 PM, {lesson.date}</div>
                    <div className="lesson-instructor">By {lesson.instructor}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Right Side - Enrollment Steps */}
        <div className="enroll-steps">
          <h2>How to Enroll</h2>
          <ol>
            <li>Create an account on our platform.</li>
            <li>Choose the course you want to enroll in.</li>
            <li>Proceed to payment and complete the purchase.</li>
            <li>Start learning by accessing the course materials.</li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
